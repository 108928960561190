import React, { useState } from "react";
import PropTypes from "prop-types";
import LogoInline from "../../../assets/svg/logo-big.inline.svg";

const VideoIframe = ({ iframe }) => {
  if (!iframe) return null;
  const [iframeUrl, setIframeUrl] = useState("about:blank");
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const updateIframeUrl = () => {
    setIframeUrl(iframe);
    setIframeLoaded(true);
  }

  return (
    <div className={"video-iframe-block"}>
      {iframeLoaded ?
        <div className={"iframe-wrapper"}>
          <iframe className={"responsive-iframe"} width={960} height={540}
                  frameBorder={0} title={"Game Iframe"} src={iframeUrl}
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; "/>
        </div> :
        <div className={"load-iframe"}>
          <button aria-label={"Load video"} className={"video-load-button"}
                  onClick={updateIframeUrl}>
            <LogoInline/>
          </button>
        </div>
      }
    </div>
  );
};

export default VideoIframe;

VideoIframe.propTypes = {
  iframe: PropTypes.string,
};
