import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../../components/shared/Layout";
import Seo from "../../components/SEO/SEO";
import Breadcrumbs from "../../components/shared/Breadcrumbs";
import FeaturedMedia from "../../components/shared/FeaturedMedia";
import ContentFromWp from "../../components/shared/ContentFromWP";
import VideoIframe from "../../components/Content/Page/VideoIframe";
import "../../assets/styles/pages/page.scss";


const page = ({ data }) => {
  const { page, nextPage, previousPage } = data;
  const { title, content, featuredImage, excerpt, uri, seo, gatsby_game } = page;

  return (
    <Layout>
      <Seo title={seo.title ?? title} description={seo.metaDesc ?? excerpt} socialImage={featuredImage?.node} uri={uri}/>
      <Breadcrumbs breadcrumbs={seo.breadcrumbs} pageTitle={seo.title} pageUrl={uri}/>
      <div className={"wrapper"}>
        {gatsby_game.videoIframe && <VideoIframe iframe={gatsby_game.videoIframe}/>}
        <h1 className="entry-title" dangerouslySetInnerHTML={{ __html: title }}/>
        <article>
          {featuredImage && <FeaturedMedia image={featuredImage}/>}

          <ContentFromWp>
            <div dangerouslySetInnerHTML={{ __html: content }}/>
          </ContentFromWp>
        </article>
        {(previousPage?.uri || nextPage?.uri) &&
          <div className={previousPage?.uri ? "prev-next-wrap" : "prev-next-wrap no-prev"}>
            {previousPage?.uri && <Link className={"button button-prev"} to={previousPage.uri}>&#8592;&nbsp; Previous</Link>}
            {nextPage?.uri && <Link className={"button button-next"} to={nextPage.uri}>Next &nbsp;&#8594;</Link>}
          </div>
        }
      </div>
    </Layout>
  );
};

export const query = graphql`
  query page($id: String!, $nextPage: String, $previousPage: String) {
    page: wpPage(id: { eq: $id }) {
      ...PageContent
    }
    nextPage: wpPage(id: { eq: $nextPage }) {
      title
      uri
    }
    previousPage: wpPage(id: { eq: $previousPage }) {
      title
      uri
    }
  }
`;

export default page;
